const app = require('../utils/core');

app.querySelector('.page-loader', function (el) {
  const pageLoader = el[0];

  const hidePageLoader = function () {
    if(window.location.pathname === '/index.html' || window.location.pathname === '/'){
    }else{
      setTimeout(()=>{pageLoader.classList.add('hidden')},1000);
    }
  };
  
  window.addEventListener('load', hidePageLoader);
});